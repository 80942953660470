<script setup lang="ts">
import type { SliceInterface, VoixLinkFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps<{
  slice: SliceInterface
  inView: boolean
  fields: {
    position: VoixSelectFieldInterface
    subtitle: VoixTextFieldInterface
    title: VoixTextFieldInterface
    titleSize: VoixSelectFieldInterface
    description: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    listTitle: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Title Description Link', group: 'LBM Copy', layouts: ['LogicbombMedia'] },
  slots: [{ name: 'default', allowedElements: ['SlicesLbmCopyEmployeeListItem'] }],
  description: '',
  preview: 'SlicesLbmCopyTitleDescriptionLink.jpg',
  fields: {
    position: {
      type: 'select',
      label: 'Position',
      options: {
        left: 'Left',
        center: 'Center',
        right: 'Right',
      },
      default: 'left',
    },
    title: {
      type: 'text',
      label: 'Title',
      group: 'Title',
    },
    subtitle: {
      type: 'text',
      label: 'Subtitle',
      group: 'Title',
    },
    titleSize: {
      type: 'select',
      label: 'Title Size',
      options: {
        'text-sm': 'Small',
        'text-md': 'Medium',
        'text-lg': 'Large',
        'text-xl': 'Extra Large',
        'text-2xl': '2 Extra Large',
        'text-3xl': '3 Extra Large',
        'text-4xl': '4 Extra Large',
        'text-5xl': '5 Extra Large',
        'text-6xl': '6 Extra Large',
        'text-7xl': '7 Extra Large',
      },
      default: 'medium',
      group: 'Title',
    },
    description: {
      type: 'wysiwyg',
      label: 'Description',
    },
    link: {
      type: 'link',
      label: 'Link',
    },
    listTitle: {
      type: 'text',
      label: 'List Title',
      enabled: false,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

// Calculate the list type based on the first slice that appears in the slot
const listType = computed(() => {
  const elements = props.slice.elements
  if (elements?.length && elements.length > 0) {
    if (elements[0].component === 'SlicesLbmCopyEmployeeListItem')
      return 'Employees'
  }

  return ''
})

const employeeElements = computed(() => {
  const employeeElements = []
  if (props.slice.elements) {
    for (const element of props.slice.elements) {
      const fields = resolveFieldsArray(element as SliceInterface)
      employeeElements.push({
        id: element.id,
        person: fields?.person,
        position: fields?.position,
      })
    }
  }
  return employeeElements
})
</script>

<template>
  <div
    class="font-lbm-serif"
    :class="{
      'grid grid-cols-1 lg:grid-cols-12': fields.listTitle.enabled,
    }"
  >
    <div
      class="px-12 lg:px-24 flex flex-col tranform-gpu duration-1000 ease-out"
      :class="{
        'justify-start': fields.position.value === 'left',
        'justify-center text-center': fields.position.value === 'center',
        'justify-end': fields.position.value === 'right',
        'lg:col-span-5': fields.listTitle.enabled && listType === 'Employees',
        '-translate-x-8 opacity-0': !inView,
      }"
    >
      <h2
        class="font-lbm-serif text-stone-200 antialiased max-w-4xl" :class="[fields.titleSize.value, {
          'mx-auto': fields.position.value === 'center',
          'tracking-tighter': fields.titleSize.value === 'text-7xl' || fields.titleSize.value === 'text-6xl' || fields.titleSize.value === 'text-5xl',
        }]"
      >
        <div
          class="uppercase tracking-widest font-lbm-sans font-light  not-italic leading-[1em]"
          :class="{
            'text-[0.25em]': fields.titleSize.value === 'text-7xl' || fields.titleSize.value === 'text-6xl' || fields.titleSize.value === 'text-5xl',
            'text-[0.35em]': fields.titleSize.value !== 'text-7xl' && fields.titleSize.value !== 'text-6xl' && fields.titleSize.value !== 'text-5xl',
          }"
        >
          {{ fields.subtitle.value }}
        </div>
        {{ fields.title.value }}
      </h2>
      <div
        class="prose prose-invert prose-lg" :class="{
          'mx-auto': fields.position.value === 'center',
        }"
      >
        <VoixWysiwyg class="font-lbm-serif text-stone-400" :field="fields.description" />
        <VoixLink :link="fields.link" class="font-lbm-sans text-stone-400" />
      </div>
    </div>

    <div class="px-12 lg:col-span-6 lg:col-start-7 flex items-center">
      <div v-if="listType === 'Employees'" class="grid grid-cols-2 text-2xl ">
        <div class="text-sm text-stone-400 border-b border-stone-800">
          Name
        </div>
        <div class="text-sm text-stone-400 border-b border-stone-800">
          Position
        </div>
        <template v-for="element in employeeElements" :key="element.id">
          <div class="py-4 border-b border-stone-800 text-lg lg:text-2xl">
            {{ element.person?.value }}
          </div>
          <div class="py-4 border-b border-stone-800 text-lg lg:text-2xl">
            {{ element.position?.value }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
